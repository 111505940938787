<template>
  <div>
    <dossier-info-header-card class="no-float margin" :dossier-info="headerCardInfo"> </dossier-info-header-card>
    <crud-editor
      ref="editor"
      :is-edit="true"
      :definition="definition"
      :fetch-by-id="FetchById"
      :disabled="hasNoEditPermission"
      :save="saveData"
      :return-to="returnTo"
      :item-type="entity"
      :page-loading="loading"
      :get-dependencies="getDependencies"
    />
    <div class="agreementform">
      <div class="agreement">
        <AgreementTable
          v-if="loanId"
          :loan-info="loanInfo"
          :loan-id="loanId"
          :loan-description="description"
          :agreement-by="Roles.Consultant"
          :display-subject="true"
          :allow-save="allowSaveConsultant"
          :on-save="$refs.editor.persistForm"
          :loading="loading"
        ></AgreementTable>
      </div>
      <div v-if="showManagerAgreement" class="agreement">
        <AgreementTable
          v-if="loanId"
          :loan-info="loanInfo"
          :loan-id="loanId"
          :loan-description="description"
          :agreement-by="Roles.Manager"
          :display-subject="false"
          :allow-save="false"
          :loading="loading"
        ></AgreementTable>
      </div>
      <div v-if="showBoardAgreement" class="agreement">
        <AgreementTable
          v-if="loanId"
          :loan-info="loanInfo"
          :loan-id="loanId"
          :loan-description="loanDescription"
          :agreement-by="Roles.Board"
          :display-subject="false"
          :allow-save="false"
          :loading="loading"
        ></AgreementTable>
      </div>
    </div>
    <div class="app-container">
      <label title="Historie">Activiteiten logboek</label>
      <el-table ref="agreementlog" :data="agreements" stripe>
        <el-table-column property="agreementByText" :label="$t('common.role')" width="200" />
        <el-table-column property="userName" :label="$t('common.username')" width="200" />
        <el-table-column
          property="agreementActionText"
          :formatter="actionFormatter"
          :label="$t('loan.agreementAction')"
          width="300"
        />
        <el-table-column property="date" :label="$t('common.date')" width="125" />
        <el-table-column property="time" :label="$t('common.time')" width="125" />
        <el-table-column property="remarks" :label="$t('common.remarks')" />
      </el-table>
    </div>
    <div>
      <AuditLog v-if="auditlog" :audit-entity="loanentity" :audit-entity-i-d="loanId" :active-y-n="true" />
    </div>
  </div>
</template>

<script>
import CrudEditor from '@/components/crud/Editor';
import formMixin from './form-mixin';
import { hasEditPermissionForCode } from '@/utils/permission';
import DossierInfoHeaderCard from '@/pages/dossiers/DossierInfoHeaderCard';
import BaseButton from '@/components/crud/BaseButton';
import AgreementTable from '@/components/DSE/AgreementTable';
import AuditLog from '@/pages/dossiers/auditlogs/index';
import { Roles } from './Roles/Roles.js';
export default {
  components: {
    CrudEditor,
    BaseButton,
    DossierInfoHeaderCard,
    AgreementTable,
    AuditLog
  },
  mixins: [formMixin],
  data() {
    return {
      auditlog: 0,
      Roles: Roles,
      headerCardInfo: {},
      loanInfo: [],
      loanAgreements: [],
      loanentity: 'Loan',
      allowSaveConsultant: true,
      showBoardAgreement: false,
      showManagerAgreement: true,
      agreementby: 0,
      agreementfrom: 0,
      description: '',
      loanId: 0,
      statusCode: {
        type: String
      },
      lastAgreementStatusCode: {
        type: String
      }
    };
  },
  computed: {
    hasNoEditPermission() {
      return !hasEditPermissionForCode(this.entity, this.$store.getters.permissions);
    },
    agreementType() {
      return this.agreementtype;
    },
    loanDescription() {
      return this.description;
    },
    loading() {
      return this.$store.getters[this.entityStore + '/isLoading'];
    },
    agreements() {
      return this.loanAgreements;
    }
  },
  methods: {
    async FetchById(id) {
      const loan = await this.fetchById(id, this);
      return loan;
    },
    saveData(form) {
      const item = this.createItemFromFormData(this, form);
      return this.$store.dispatch(this.entityStore + '/persistItem', item);
    }
  }
};
</script>

<style scoped>
.no-float {
  float: none;
}

.margin {
  margin: 10px;
}
</style>
<style>
.remark {
  margin: 5px;
  margin-left: 20px;
  height: 4em;
  width: 90%;
}

.green-btn {
  margin: 5px;
}

.red-btn {
  margin: 5px;
}

h4 {
  margin: 5px;
}
</style>
